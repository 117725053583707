<!--  -->
<template>
  <div class="forgotPwd-page">
    <div class="wrap">
      <div class="wrap_left">
        <h2>Welcome Back !</h2>
        <p>
          A full-suite education solution that <br />empowers educators and
          learners around <br />the world. kids around the world
        </p>
        <div class="img">
          <img :src="require('@/assets/images/forget_pwd_bg.png')" alt="" />
        </div>
      </div>
      <div class="wrap_right">
        <div class="title">Reset Password</div>
        <!-- <p class="remark">
          A reset password link will be sent to the email address below.
        </p> -->
        <div class="form" v-if="!isSuccess">
          <gg-input
            ref="newPassword"
            v-model="formData.newPassword"
            placeholder="New password"
            type="password"
            :error="formData.newPwdErrorObj"
          />

          <gg-input
            ref="confirmNewPassword"
            v-model="formData.confirmNewPassword"
            placeholder="Confirm password"
            type="password"
            :error="formData.confirmPwdErrorObj"
          />
          <div class="bottom">
            <baseButton
              class="submit"
              :disabled="!isDisabled"
              type="primary"
              @click="handleLogin"
              >Next</baseButton
            >
          </div>
        </div>
        <div class="successful-info" v-if="isSuccess">
          <p>You have successfully reset password for your EDOOVO Account.</p>
          <p>
            Log in to EDOOVO at
            <router-link tag="a" to="/login">www.edoovo.com.</router-link>
          </p>
          <p>Thank you!</p>
        </div>
      </div>
    </div>
    <Snackbar
      :visible.sync="snackbarData.visible"
      :content="snackbarData.content"
    />
  </div>
</template>

<script>
import { Ajax } from "@/common";
import { lightFormat } from "date-fns";
export default {
  data() {
    return {
      formData: {
        newPassword: "",
        confirmNewPassword: "",
        newPwdErrorObj: {
          show: true,
          message: "",
        },
        confirmPwdErrorObj: {
          show: true,
          message: "",
        },
      },
      email: "",
      snackbarData: {
        visible: false,
        content: "",
      },
      isSuccess: false,
    };
  },
  computed: {
    isDisabled() {
      if (
        this.formData.newPwdErrorObj.show ||
        this.formData.confirmPwdErrorObj.show
      ) {
        return false;
      } else {
        return true;
      }
    },
  },
  watch: {
    "formData.newPassword"(res) {
      if (res.length < 8) {
        this.formData.newPwdErrorObj = {
          show: true,
          message: "Must have at least 8 characters",
        };
      } else {
        this.formData.newPwdErrorObj = {
          show: false,
          message: "Must have at least 8 characters",
        };
      }
    },
    "formData.confirmNewPassword"(res) {
      if (res.length < 8) {
        this.formData.confirmPwdErrorObj = {
          show: true,
          message: "Must have at least 8 characters",
        };
      } else {
        this.formData.confirmPwdErrorObj = {
          show: false,
          message: "Must have at least 8 characters",
        };
      }
    },
  },
  methods: {
    handleSnack(content) {
      this.snackbarData.content = content;
      this.snackbarData.visible = true;
    },
    handleLogin(e) {
      if (this.formData.newPassword !== this.formData.confirmNewPassword) {
        this.formData.confirmPwdErrorObj = {
          show: true,
          message: "Passwords do not match",
        };
        return false;
      }

      Ajax.post("/usermanage/password/updatePasswdByAccount", {
        email: this.email,
        password: this.formData.confirmNewPassword,
      }).then((res) => {
        if (res.code === "0000") {
          this.handleSnack("Reset password link has been sent");
          this.isSuccess = !this.isSuccess;
        } else {
          this.formData.newPwdErrorObj = {
            show: true,
            message: res.message,
          };
        }
      });
    },
    handleCheckExpire() {
      Ajax.post("/usermanage/email/checkEmailExpire", {
        email: this.email
      }).then(res => {
        if (!res.data) {
          alert('这里要阻止它进入页面，弹层提示邮箱验证已过期')
        }
      })
    }
  },
  created() {
    this.email = this.$route.query.email
    this.handleCheckExpire();
  },
};
</script>
<style lang='less' scoped>
//@import url(); 引入公共css类

.forgotPwd-page {
  width: 1424px;
  height: 584px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: url("~@/assets/images/login_bg.png") no-repeat 50% 100%;
  overflow: hidden;
  .wrap {
    display: flex;
    width: 800px;
    height: 560px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    box-sizing: border-box;
    box-shadow: 0 0 12px rgba(0, 0, 0, 0.16);
    border-radius: 16px;
    overflow: hidden;
    .wrap_left {
      flex: 1 1 50%;
      height: 100%;
      background: #255180;
      padding: 50px 32px;
      text-align: left;

      h2 {
        font-family: OpenSans-Bold;
        font-size: 32px;
        font-weight: 700;
        color: #fff;
        margin-bottom: 17px;
        line-height: 45px;
      }
      p {
        font-size: 16px;
        font-family: Roboto;
        font-weight: 500;
        color: #fff;
        line-height: 24px;
        margin-bottom: 58px;
      }
      .img {
        padding: 0 16px;
      }
    }
    .wrap_right {
      flex: 1 1 50%;
      padding: 50px 32px;
      text-align: left;
      background: #fff;
      .title {
        height: 43px;
        line-height: 43px;
        font-family: OpenSans-Bold;
        color: #202124;
        font-size: 32px;
      }
      // .remark {
      //   font-size: 14px;
      //   color: #202124;
      //   margin-bottom: 24px;
      //   height: 70px;
      //   line-height: 19px;
      // }
      .form {
        margin-top: 100px;
      }
      .successful-info {
        margin-top: 40px;
        font-family: Roboto;
        font-size: 14px;
        color: #202124;
        p {
          margin-bottom: 20px;
          &:last-child {
            margin-bottom: 0;
          }
        }
      }
      .bottom {
        margin-top: 30px;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        .cancel {
          color: #5f6368;
          cursor: pointer;
          font-family: OpenSans-Bold;
          margin-right: 19px;
        }
        .submit {
          // width: 64px;
          // height: 32px;
          background: #1a73e8;
          color: #fff;
          // font-family: OpenSans-Bold;
          // border-radius: 4px;
          // text-align: center;
          // line-height: 32px;
          cursor: pointer;
          &.disabled {
            background: #b4b4b4;
            cursor: not-allowed;
          }
        }
      }
    }
  }
}
</style>